import React from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import { Title, SubTitle } from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Screen Resolution",
    link: "/screen-resolution/",
  },
]
const seeAlsoArray = [
  "/what-is-my-browser-version",
  "/ip-address",
  "/where-am-i-location-latitude-longitude",
  "/find-location-of-ip-address",
]

function Resolution() {
  let width, height, colorDepth
  if (typeof window !== "undefined") {
    width = window.screen.width
    height = window.screen.height
    colorDepth = window.screen.colorDepth
  }
  return (
    <>
      <p className="h4">
        Screen Resolution:
        <strong>
          {" "}
          {width} &times; {height}{" "}
        </strong>
      </p>
      <p className="h4">
        Screen Width: <strong> {width} pixels </strong>
      </p>
      <p className="h4">
        Screen Height: <strong> {height} pixels </strong>
      </p>
      <p className="h4">
        Color Depth: <strong> {colorDepth} bits per pixel </strong>
      </p>
    </>
  )
}

function ScreenResolution(props) {
  return (
    <Layout location={props.location}>
      <SEO
        title="Screen Resolution - what is my screen resolution?"
        description="Screen Resolution, what is my screen resolution? Simple Online tool to find your screen resolution, your screen height, and screen width."
        keywords={[
          "screen resolution,what resolution is my monitor, monitor resolution size,my Color Depth,screen height,screen width, what is my screen resolution,what resolution is my monitor,whats my resolution,screen resolution test,my screen, what is my screen size,whats my screen resolution,what is screen resolution.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>What Is My Screen Resolution?</Title>
        <SubTitle>Screen Resolution</SubTitle>

        <br />
        <Resolution />

        <FlexDiv maxWidth="400px">
          <SmallImg
            filename="screen.png"
            alt="screen resolution height width"
          />
        </FlexDiv>

        <br />
        <h3>Monitor Resolution</h3>
        <p>
          Resolution is normally one of the first specifications considered when
          buying for a new monitor. we give you the screen width and the screen
          height on pixels. Example: a resolution of 1920 x 1080 mean that the
          screen width is 1920 pixels while the height of the screen is 1080
          pixels. In this case of a monitor with an industry-standard Full HD
          1080p resolution.
          <br />
          The higher resolution is the more detailed image can be because a
          higher resolution monitor will be created of more pixels than a lower
          resolution monitor.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ScreenResolution
